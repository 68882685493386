var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.accounts)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"secondary d-flex align-center justify-space-between white--text py-0"},[_c('div',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"mt-5 mb-0",attrs:{"dark":"","outlined":"","dense":"","value":_vm.computedDateFormattedMomentjs,"readonly":"","prepend-inner-icon":"mdi-calendar-month-outline"}},on))]}}],null,false,4045597762),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"scrollable":"","type":"month"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.getData()}}},[_vm._v("OK")])],1)],1)],1),_c('TableSearch',{staticClass:"mt-n1",on:{"input":function (newText) {_vm.search = newText}}})],1),(_vm.accounts)?_c('v-data-table',{staticClass:"tableColor",attrs:{"item-key":"AccountName","loading":_vm.loading,"search":_vm.search,"headers":_vm.headers,"items":_vm.accounts,"items-per-page":10},scopedSlots:_vm._u([{key:"item.GetInfo",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"open-delay":"2500","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","color":"blue","dark":"","small":""},on:{"click":function($event){return _vm.getRecords(item)}}},on),[_c('span',{staticClass:"caption font-weight-light text-capitalize"},[_vm._v("View records")]),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("mdi-file-document-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("View all records.")])])]}},{key:"item.AccountBalance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toCurrencyFilter(item.AccountBalance, _vm.currencyData))+" ")]}}],null,false,2045308585)}):_vm._e()],1):_vm._e(),_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.depositsHeldRecordsDialog),callback:function ($$v) {_vm.depositsHeldRecordsDialog=$$v},expression:"depositsHeldRecordsDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"secondary white--text"},[_vm._v(" "+_vm._s(_vm.recordDialog.type)+" Records "),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"white"},on:{"click":function($event){$event.stopPropagation();return _vm.closeDepositsRecordsDialog.apply(null, arguments)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_vm._v("Back ")],1)],1),_c('v-card-text',[_c('v-container',[(_vm.fetchingRecords)?_c('div',{staticClass:"mt-5 text-center"},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}),_c('p',{staticClass:"font-italic primary--text text--lighten-4 mt-5"},[_vm._v("...Fetching data")])],1):_c('DepositsHeldRecords',{attrs:{"records":_vm.records},on:{"closeRecordsDialog":_vm.closeDepositsRecordsDialog}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }