var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.unpaidInvoices)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"secondary white--text d-flex justify-space-between"},[_c('div',{staticClass:"d-md-flex align-start"},[_c('v-btn',{staticClass:"ml-md-3 mt-md-1",attrs:{"text":"","dark":""},on:{"click":function($event){return _vm.exportReport()}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-export-variant")]),_c('span',[_vm._v("Export")])],1)],1),_c('TableSearch',{on:{"input":function (newText) {_vm.search = newText}}})],1),(_vm.unpaidInvoices)?_c('v-data-table',{staticClass:"tableColor",attrs:{"loading":_vm.loading,"search":_vm.search,"headers":_vm.headers,"items":_vm.unpaidInvoices,"items-per-page":10},scopedSlots:_vm._u([{key:"item.InvoiceNumber",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){return _vm.downloadInvoice(item.OID)}}},[_vm._v(_vm._s(item.InvoiceNumber))])],1)]}},{key:"item.InvoicedDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.InvoicedDate))+" ")])]}},{key:"item.InDebtSince",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.InDebtSince))+" ")])]}},{key:"item.MainBalance",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.toCurrencyFilter(item.MainBalance, _vm.currencyData))+" ")])]}},{key:"item.SalesNetAmount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.toCurrencyFilter(item.SalesNetAmount, _vm.currencyData))+" ")])]}},{key:"item.SalesVATAmount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.toCurrencyFilter(item.SalesVATAmount, _vm.currencyData))+" ")])]}},{key:"item.SalesGrossAmount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.toCurrencyFilter(item.SalesGrossAmount, _vm.currencyData))+" ")])]}},{key:"item.PaidAmountNet",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.toCurrencyFilter(item.PaidAmountNet, _vm.currencyData))+" ")])]}},{key:"item.PaidAmountVAT",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.toCurrencyFilter(item.PaidAmountVAT, _vm.currencyData))+" ")])]}}],null,false,763437481)}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }