<template>
    <div>
        <v-card flat v-if="unpaidInvoices">
          <v-card-title class="secondary white--text d-flex justify-space-between">
            <div class="d-md-flex align-start">
              <v-btn class="ml-md-3 mt-md-1" text dark @click="exportReport()">
                <v-icon small  left>mdi-export-variant</v-icon>
                <span>Export</span>
              </v-btn>
            </div>
            <TableSearch @input="(newText) => {search = newText}" />
          </v-card-title>
  
          <v-data-table
            class="tableColor"
            v-if="unpaidInvoices"
            :loading="loading"
            :search="search"
            :headers="headers"
            :items="unpaidInvoices"
            :items-per-page="10"
          >
          <template v-slot:item.InvoiceNumber="{ item }">
              <span>
                <v-btn depressed @click="downloadInvoice(item.OID)">{{item.InvoiceNumber}}</v-btn>
              </span>
            </template>
            <template v-slot:item.InvoicedDate="{ item }">
              <span>
                {{ item.InvoicedDate | formatDate }}
              </span>
            </template>
            <template v-slot:item.InDebtSince="{ item }">
              <span>
                {{ item.InDebtSince | formatDate }}
              </span>
            </template>
            <template v-slot:item.MainBalance="{ item }">
              <span>
                {{ toCurrencyFilter(item.MainBalance, currencyData) }}
              </span>
            </template>
            <template v-slot:item.SalesNetAmount="{ item }">
              <span>
                {{ toCurrencyFilter(item.SalesNetAmount, currencyData) }}
              </span>
            </template>
            <template v-slot:item.SalesVATAmount="{ item }">
              <span>
                {{ toCurrencyFilter(item.SalesVATAmount, currencyData) }}
              </span>
            </template>
            <template v-slot:item.SalesGrossAmount="{ item }">
              <span>
                {{ toCurrencyFilter(item.SalesGrossAmount, currencyData) }}
              </span>
            </template>
            <template v-slot:item.PaidAmountNet="{ item }">
              <span>
                {{ toCurrencyFilter(item.PaidAmountNet, currencyData) }}
              </span>
            </template>
            <template v-slot:item.PaidAmountVAT="{ item }">
              <span>
                {{ toCurrencyFilter(item.PaidAmountVAT, currencyData) }}
              </span>
            </template>
          </v-data-table>  
        </v-card>
    </div>
  </template>
  
  <script>
  import {mapGetters, mapState} from 'vuex'
  import moment from 'moment/src/moment'
  import TableSearch from '@/components/TableComponents/TableSearch.vue'
  import {toCurrencyFilter} from '@/jsFunctions/toCurrencyFilterFunction.js'
  
  export default {
    name: 'unpaidInvoicesTable',
    components: {
      TableSearch
    },
    data: () => ({
      loading: false,
      search: '',
      headers: []
    }),
    methods: {
      exportReport() {
        this.$store.dispatch('UnpaidInvoices/exportUnpaidInvoices') 
      },
      downloadInvoice(OID) {
      this.loading = 'secondary'
      this.$store.dispatch('OutgoingPayments/downloadInvoice', OID)
        .then((response) => {
          this.loading = false
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL
          fileLink.setAttribute('download', `${invoice.GlobalReference}.pdf`);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
      },
      setHeaders(vatRegistered){
        this.headers = [
          {
            text: 'Person',
            align: 'left',
            value: 'Person',
          },
          { text: 'Person Info', value: 'PersonInfo' },
          { text: 'Person Reference', value: 'PersonRef' },
          { text: 'Property', value: 'Property' },
          { text: 'Main Balance', value: 'MainBalance' },
          { text: 'Invoiced Date', value: 'InvoicedDate' },
          { text: 'Invoice Number', value: 'InvoiceNumber' },
          { text: 'SalesNetAmount', value: 'SalesNetAmount' },
          { text: 'SalesVATAmount', value: 'SalesVATAmount' },
          { text: 'SalesGrossAmount', value: 'SalesGrossAmount' },
          { text: 'PaidAmountNet', value: 'PaidAmountNet' },
          { text: 'PaidAmountVAT', value: 'PaidAmountVAT' },
        ]
          if(!vatRegistered){
          this.headers = [
          {
            text: 'Person',
            align: 'left',
            value: 'Person',
          },
          { text: 'Person Info', value: 'PersonInfo' },
          { text: 'Person Reference', value: 'PersonRef' },
          { text: 'Property', value: 'Property' },
          { text: 'Main Balance', value: 'MainBalance' },
          { text: 'Invoiced Date', value: 'InvoicedDate' },
          { text: 'Invoice Number', value: 'InvoiceNumber' },
          { text: 'SalesNetAmount', value: 'SalesNetAmount' },       
          { text: 'PaidAmountNet', value: 'PaidAmountNet' },
        ]
        }
      },
    toCurrencyFilter
    },
    computed: {
      ...mapState({
        unpaidInvoices: state => state.UnpaidInvoices.unpaidInvoices,
      }),
      ...mapGetters({
        currencyData: "CurrencyData/currencyInfo"
      }),
      computedDateFormattedMomentjs () {
        return this.date ? moment(this.date).format('MMMM YYYY') : ''
      },
    },
    created() {
      this.loading = true
      this.$store.dispatch('UnpaidInvoices/getUnpaidInvoices', this.date).then(() => {
        this.setHeaders(this.unpaidInvoices[0].IsVATRegistered)
        this.loading = false
      })
    },
  }
  </script>