<template>
  <div>
      <v-card flat v-if="paidInvoices">
        <v-card-title class="secondary white--text d-flex justify-space-between">
          <div class="mb-n5 d-md-flex align-start">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  dark
                  outlined dense
                  :value="computedDateFormattedMomentjs"
                  readonly
                  prepend-inner-icon="mdi-calendar-month-outline"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" scrollable type="month">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                <v-btn text color="primary" @click="getData()">OK</v-btn>
              </v-date-picker>
            </v-menu>
            <v-btn class="ml-md-3 mt-md-1" text dark @click="exportMonth()">
              <v-icon small  left>mdi-export-variant</v-icon>
              <span>Export</span>
            </v-btn>
          </div>
          <TableSearch @input="(newText) => {search = newText}" />
        </v-card-title>

        <v-data-table
          class="tableColor"
          v-if="paidInvoices"
          :loading="loading"
          :search="search"
          :headers="headers"
          :items="paidInvoices"
          :items-per-page="10"
        >
          <template v-slot:item.InvoicedDate="{ item }">
            <span>
              {{ item.InvoicedDate | formatDate }}
            </span>
          </template>
          <template v-slot:item.ManagementFee="{ item }">
            <span>
              {{ item.ManagementFee }}%
            </span>
          </template>
          <template v-slot:item.GuaranteedRentFee="{ item }">
            <span>
              {{ toCurrencyFilter(item.GuaranteedRentFee, currencyData) }}
            </span>
          </template>
          <template v-slot:item.SalesNetAmount="{ item }">
            <span>
              {{ toCurrencyFilter(item.SalesNetAmount, currencyData) }}
            </span>
          </template>
          <template v-slot:item.SalesVATAmount="{ item }">
            <span>
              {{ toCurrencyFilter(item.SalesVATAmount, currencyData) }}
            </span>
          </template>
          <template v-slot:item.SalesGrossAmount="{ item }">
            <span>
              {{ toCurrencyFilter(item.SalesGrossAmount, currencyData) }}
            </span>
          </template>
        </v-data-table>  
      </v-card>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import moment from 'moment/src/moment'
import TableSearch from '@/components/TableComponents/TableSearch.vue'
import { toCurrencyFilter } from '../../jsFunctions/toCurrencyFilterFunction'
export default {
  name: 'paidInvoicesTable',
  components: {
    TableSearch
  },
  data: () => ({
    loading: false,
    search: '',
    date: new Date().toISOString().substr(0, 7),
    menu: false,
  }),
  methods: {
    exportMonth() {
      this.$store.dispatch('PaidInvoices/exportPaidInvoices', this.date) 
    },
    getData() {
      this.loading = true
      this.menu = false
      this.$store.dispatch('PaidInvoices/getPaidInvoices', this.date).then(() => {
        this.loading = false
      })
    },
    setHeaders(vatRegistered){
      this.headers = [
      {
        text: 'Person',
        align: 'left',
        value: 'Person',
      },
      { text: 'Property', value: 'Property' },
      { text: 'Service Type', value: 'ServiceType' },
      { text: 'Management Fee', value: 'ManagementFee' },
      { text: 'GuaranteedRentFee', value: 'GuaranteedRentFee' },
      { text: 'SalesType', value: 'SalesType' },
      { text: 'InvoiceNumber', value: 'InvoiceNumber' },
      { text: 'SalesNetAmount', value: 'SalesNetAmount' },
      { text: 'InvoicedDate', value: 'InvoicedDate' },
      { text: 'SalesVATAmount', value: 'SalesVATAmount' },
      { text: 'SalesGrossAmount', value: 'SalesGrossAmount' },
      { text: 'InvoicesFullyPaid', value: 'InvoicesFullyPaid' },
      ]
      if(!vatRegistered){
        this.headers = [
          {
            text: 'Person',
            align: 'left',
            value: 'Person',
          },
          { text: 'Property', value: 'Property' },
          { text: 'Service Type', value: 'ServiceType' },
          { text: 'Management Fee', value: 'ManagementFee' },
          { text: 'GuaranteedRentFee', value: 'GuaranteedRentFee' },
          { text: 'SalesType', value: 'SalesType' },
          { text: 'InvoiceNumber', value: 'InvoiceNumber' },
          { text: 'SalesNetAmount', value: 'SalesNetAmount' },
          { text: 'InvoicedDate', value: 'InvoicedDate' },
          { text: 'InvoicesFullyPaid', value: 'InvoicesFullyPaid' },
        ] 
      }
    },
    toCurrencyFilter
  },
  computed: {
    ...mapState({
      paidInvoices: state => state.PaidInvoices.paidInvoices,
    }),
    ...mapGetters({
      currencyData: "CurrencyData/currencyInfo"
    }),
    computedDateFormattedMomentjs () {
      return this.date ? moment(this.date).format('MMMM YYYY') : ''
    }
  },
  created() {
    this.loading = true
    this.$store.dispatch('PaidInvoices/getPaidInvoices', this.date).then(() => {
      this.setHeaders(this.paidInvoices[0].IsVATRegistered);
      this.loading = false
    })
    console.log(this.headers);

  }
}
</script>