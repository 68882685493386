<template>
  <div>
    <v-card flat class="mt-5">
      <v-card-title class="py-2 primary white--text font-weight-light">
        Records
        <v-spacer></v-spacer>
        <v-btn @click="backToReports" small color="white" fab text>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        class="tableColor"
        :headers="headers"
        :items="records.Data"
        :items-per-page="10"
        item-key="LandlordRef"
        :loading="loading"
      >
        <template v-slot:item.FullReport="{  item }">
          <v-btn 
            @click="openDialog(item)"
            color="secondary" 
            depressed 
            small
          >
            <span class="custom-transform-class text-none">View full report</span>
            <v-icon right small>mdi-text-box-search-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <!-- Full report dialog -->
    <v-dialog
      v-model="dialog"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          <span class="font-weight-bold">Full report</span> 
          <v-spacer></v-spacer>
          <v-btn text color="grey" @click.stop="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <!-- {{selectedItem}} -->
          <v-divider class="mb-3"></v-divider>
          <p><span class="font-weight-bold">Name: </span>{{selectedItem.FirstName}} {{selectedItem.Surname}}</p>
          <p><span class="font-weight-bold">Tenant Ref:</span> {{selectedItem.TenantRef}}</p>
          <p><span class="font-weight-bold">Landlord Ref:</span> {{selectedItem.LandlordRef}}</p>
          <p><span class="font-weight-bold">Branch</span> {{selectedItem.Branch}}</p>
          <p><span class="font-weight-bold">Property Number:</span> {{selectedItem.PropertyNumber}}</p>
          <p><span class="font-weight-bold">Property Address:</span> {{selectedItem.PropertyAddressLine1}}</p>
          <p><span class="font-weight-bold">Signed:</span> {{selectedItem.SignedDate}}</p>
          <p><span class="font-weight-bold">Start Date:</span> {{selectedItem.StartDate}}</p>
          <p><span class="font-weight-bold">Fixed Date:</span> {{selectedItem.FixedDate}}</p>
          <p><span class="font-weight-bold">End Date:</span> {{selectedItem.EndDate}}</p>
          <p><span class="font-weight-bold">Tenancy Ref:</span> {{selectedItem.TenancyRef}}</p>
          <p><span class="font-weight-bold">Tenancy Historic Bond Ref:</span> {{selectedItem.TenancyHistoricBondReference}}</p>
          <p><span class="font-weight-bold">Service Type:</span> {{selectedItem.ServiceType}}</p>
          <p><span class="font-weight-bold">Deposit Scheme:</span> {{selectedItem.DepositScheme}}</p>
          <p><span class="font-weight-bold">Last Credit On Main Account:</span> {{selectedItem.LastCreditOnMainAccount}}</p>
          <p><span class="font-weight-bold">Last Credit On Bond Held Account:</span> {{selectedItem.LastCreditOnBondHeldAccount}}</p>
          <p><span class="font-weight-bold">Last Debit On Bond Held Account:</span> {{selectedItem.LastDebitOnBondHeldAccount}}</p>
          <p><span class="font-weight-bold">Advertised Bond:</span> {{toCurrencyFilter(parseFloat(selectedItem.AdvertisedBond), currencyData)}}</p>
          <p><span class="font-weight-bold">Bond Held:</span> {{toCurrencyFilter(parseFloat(selectedItem.BondHeld), currencyData)}}</p>
          <p><span class="font-weight-bold">Managed By Staff:</span> {{selectedItem.ManagedByStaff}}</p>
        </v-card-text>
      </v-card>

    </v-dialog>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { toCurrencyFilter } from '../../jsFunctions/toCurrencyFilterFunction'

export default {
  props: ['records'],
  data() {
    return {
      loading: false,
      dialog: false,
      selectedItem: '',
      headers: [
        {
          text: 'Name',
          align: 'left',
          value: 'FirstName',
        },
        { text: 'Property', value: 'PropertyAddressLine1' },
        { text: 'Branch', value: 'Branch' },
        { text: '', value: 'FullReport', align: 'end' },
      ],
    }
  },
  methods: {
    backToReports() {
      this.$emit('closeRecordsDialog')
    },
    openDialog(item) {
      this.dialog = true
      this.selectedItem = item
    },
    toCurrencyFilter
  },
  computed: {
    ...mapGetters({
      currencyData: "CurrencyData/currencyInfo"
    }),
  },
  watch: {
    records(val) {
      if(!val) {
        this.loading = true
      } else {
          this.loading = false
      }
    }
  }
}
</script>

<style>

</style>