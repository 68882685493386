<template>
    <div>
        <v-card flat v-if="RStBReport">
          <v-card-title class="secondary white--text d-flex justify-space-between">
            <div class="mb-n5 d-md-flex align-start">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    dark
                    outlined dense
                    :value="computedDateFormattedMomentjs"
                    readonly
                    prepend-inner-icon="mdi-calendar-month-outline"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="getData()">OK</v-btn>
                </v-date-picker>
              </v-menu>
              <v-btn class="ml-md-3 mt-md-1" text dark @click="exportMonth()">
                <v-icon small  left>mdi-export-variant</v-icon>
                <span>Export</span>
              </v-btn>
            </div>
            <TableSearch @input="(newText) => {search = newText}" />
          </v-card-title>
  
          <v-data-table
            class="tableColor"
            v-if="RStBReport"
            :loading="loading"
            :search="search"
            :headers="headers"
            :items="RStBReport"
            :items-per-page="10"
          >
          <template v-slot:item.Subtotal="{item}">
            {{ toCurrencyFilter(item.Subtotal, currencyData) }}
          </template>
          <template v-slot:item.Total="{item}">
          {{ toCurrencyFilter(item.Total, currencyData) }}
        </template>
        <template v-slot:item.GrandTotal="{item}">
          {{ toCurrencyFilter(item.GrandTotal, currencyData) }}
        </template>
          </v-data-table>  
        </v-card>
    </div>
  </template>
  
  <script>
  import {mapState, mapGetters} from 'vuex'
  import moment from 'moment/src/moment'
  import TableSearch from '@/components/TableComponents/TableSearch.vue'
  import { toCurrencyFilter } from '../../jsFunctions/toCurrencyFilterFunction'
  export default {
    name: 'paidInvoicesTable',
    components: {
      TableSearch
    },
    data: () => ({
      loading: false,
      search: '',
      date: new Date().toISOString().substr(0, 7),
      menu: false,
      headers: [
        { text: 'Group', value: 'Group' },
        { text: 'Category', value: 'Category' },
        { text: 'Subtotal', value: 'Subtotal' },
        { text: 'Total', value: 'Total' },
        { text: 'Grand Total', value: 'GrandTotal' }
      ],
    }),
    methods: {
      exportMonth() {
        this.$store.dispatch('RStB/exportRStB', this.date) 
      },
      getData() {
        this.loading = true
        this.menu = false
        this.$store.dispatch('RStB/getRStB', this.date).then(() => {
          this.loading = false
        })
      },
      toCurrencyFilter
    },
    computed: {
      ...mapState({
        RStBReport: state => state.RStB.RStBReport,
      }),
      ...mapGetters({
        currencyData: "CurrencyData/currencyInfo"
      }),
      computedDateFormattedMomentjs () {
        return this.date ? moment(this.date).format('MMMM YYYY') : ''
      },
    },
    created() {
      this.loading = true
      this.$store.dispatch('RStB/getRStB', this.date).then(() => {
        this.loading = false
      })
  
    }
  }
  </script>