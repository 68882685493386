<template>
  <v-container class="py-0">
    <transition name="fade" mode="out-in" appear>
      <PageTitle class="d-block d-sm-none">Reports</PageTitle>
    </transition>

    <transition name="slide-left-fade" mode="out-in" appear>
      <v-tabs
        v-model="tab"
        class="mt-5 mt-sm-1"
        color="tabColor"
        slider-color="tabColor"
        center-active
        background-color="tertiary"
        show-arrows
        height="55"
      >
        <v-tab ripple>
          Deposits held 
        </v-tab>
        <v-tab ripple>
          Paid invoices
        </v-tab>
        <v-tab ripple>
          Unpaid invoices
        </v-tab>
        <v-tab ripple>
          Reconcile Report
        </v-tab>
        <v-spacer></v-spacer>
        <span class="d-none d-sm-block">
          <PageTitle>Reports</PageTitle>
        </span>
      </v-tabs>
    </transition>

    <transition name="slide-fade" mode="out-in" appear>
      <div>
        <v-tabs-items v-model="tab" class="pt-2 tertiary">
          <v-tab-item>
            <DepositsHeld />
            <Skeleton v-if="!accounts"/>
          </v-tab-item> 
          <v-tab-item>
            <PaidInvoices />
            <Skeleton v-if="!paidInvoices"/>
          </v-tab-item>
          <v-tab-item>
            <UnpaidInvoices />
            <Skeleton v-if="!unpaidInvoices"/>
          </v-tab-item>
          <v-tab-item>
            <RStB />
            <Skeleton v-if="!rstb"/>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </transition>
  </v-container>
</template>

<script>
import {mapState} from 'vuex'
import PaidInvoices from '@/components/ReportsTables/PaidInvoices'
import DepositsHeld from '@/components/ReportsTables/DepositsHeld'
import Skeleton from '@/components/SkeletonLoaders/SkeletonLoaderTable.vue'
import PageTitle from '@/components/Slots/PageTitle.vue'
import UnpaidInvoices from '@/components/ReportsTables/UnpaidInvoices'
import RStB from "@/components/ReportsTables/RStBTable"

export default {
  components: {
    PaidInvoices,
    DepositsHeld,
    Skeleton,
    PageTitle,
    UnpaidInvoices,
    RStB
},
  created() {
    //Paid invoice and DepositsHeld components are making API requests directly from inside the component to actions inside the respective $store modules
  },
  data() {
    return {
      tab: null
    }
  },
  computed: {
    ...mapState({
      paidInvoices: state => state.PaidInvoices.paidInvoices,
      accounts: state => state.DepositsHeld.accounts,
      unpaidInvoices: state => state.UnpaidInvoices.unpaidInvoices,
      rstb: state => state.RStB.RStBReport
    })
  }
}
</script>

<style>

</style>