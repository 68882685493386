var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.paidInvoices)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"secondary white--text d-flex justify-space-between"},[_c('div',{staticClass:"mb-n5 d-md-flex align-start"},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"dark":"","outlined":"","dense":"","value":_vm.computedDateFormattedMomentjs,"readonly":"","prepend-inner-icon":"mdi-calendar-month-outline"}},on))]}}],null,false,1285957201),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"scrollable":"","type":"month"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.getData()}}},[_vm._v("OK")])],1)],1),_c('v-btn',{staticClass:"ml-md-3 mt-md-1",attrs:{"text":"","dark":""},on:{"click":function($event){return _vm.exportMonth()}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-export-variant")]),_c('span',[_vm._v("Export")])],1)],1),_c('TableSearch',{on:{"input":function (newText) {_vm.search = newText}}})],1),(_vm.paidInvoices)?_c('v-data-table',{staticClass:"tableColor",attrs:{"loading":_vm.loading,"search":_vm.search,"headers":_vm.headers,"items":_vm.paidInvoices,"items-per-page":10},scopedSlots:_vm._u([{key:"item.InvoicedDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.InvoicedDate))+" ")])]}},{key:"item.ManagementFee",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.ManagementFee)+"% ")])]}},{key:"item.GuaranteedRentFee",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.toCurrencyFilter(item.GuaranteedRentFee, _vm.currencyData))+" ")])]}},{key:"item.SalesNetAmount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.toCurrencyFilter(item.SalesNetAmount, _vm.currencyData))+" ")])]}},{key:"item.SalesVATAmount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.toCurrencyFilter(item.SalesVATAmount, _vm.currencyData))+" ")])]}},{key:"item.SalesGrossAmount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.toCurrencyFilter(item.SalesGrossAmount, _vm.currencyData))+" ")])]}}],null,false,3601051970)}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }