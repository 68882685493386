<template>
  <div>
    <v-card flat v-if="accounts">
      <v-card-title class="secondary d-flex align-center justify-space-between white--text py-0">
        <div>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                dark
                outlined dense
                :value="computedDateFormattedMomentjs"
                readonly
                prepend-inner-icon="mdi-calendar-month-outline"
                v-on="on"
                class="mt-5 mb-0"

              ></v-text-field>
            </template>
            <v-date-picker v-model="date" scrollable type="month">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
              <v-btn text color="primary" @click="getData()">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <TableSearch class="mt-n1" @input="(newText) => {search = newText}" />
      </v-card-title>

      <v-data-table
        class="tableColor"
        v-if="accounts"
        item-key="AccountName"
        :loading="loading"
        :search="search"
        :headers="headers"
        :items="accounts"
        :items-per-page="10"
      >
        <!-- Navigate to records table -->
        <template v-slot:item.GetInfo="{item}">
          <v-tooltip open-delay="2500" bottom>
            <template v-slot:activator="{ on }">
              <v-btn depressed v-on="on" color="blue" dark small @click="getRecords(item)" >
                <span class="caption font-weight-light text-capitalize">View records</span>
                <v-icon right small>mdi-file-document-outline</v-icon>
              </v-btn>  
            </template>
            <span>View all records.</span>
          </v-tooltip>
        </template>
        <!-- Navigate to records table -->
        <template v-slot:item.AccountBalance="{item}">
          {{ toCurrencyFilter(item.AccountBalance, currencyData) }}
        </template>

      </v-data-table>  
    </v-card>

    <!-- Full screen records dialog -->
    <v-dialog
      v-model="depositsHeldRecordsDialog"
      fullscreen
    >
      <v-card>
        <v-card-title class="secondary white--text">
           {{recordDialog.type}} Records
          <v-spacer></v-spacer>
          <v-btn outlined color="white" @click.stop="closeDepositsRecordsDialog">
            <v-icon left>mdi-arrow-left</v-icon>Back
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container >
            <div v-if="fetchingRecords" class="mt-5 text-center">
              <v-progress-linear
                indeterminate
                color="primary"
              ></v-progress-linear>
              <p class="font-italic primary--text text--lighten-4 mt-5">...Fetching data</p>
            </div>
            <DepositsHeldRecords
              @closeRecordsDialog="closeDepositsRecordsDialog"
              v-else
              :records="records"
            />
            
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import moment from 'moment/src/moment'
import DepositsHeldRecords from './DepositsHeldRecordsTable'
import TableSearch from '@/components/TableComponents/TableSearch.vue'
import { toCurrencyFilter } from '../../jsFunctions/toCurrencyFilterFunction'

export default {
  name: 'deposits-held',
  components: {
    DepositsHeldRecords,
    TableSearch
  },
  data: () => ({
    depositsHeldRecordsDialog: false,
    recordDialog: {
      type: ''
    },
    fetchingRecords: false,
    loading: false,
    search: '',
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    headers: [
      {
        text: 'Name',
        align: 'left',
        value: 'AccountName',
      },
      { text: 'AccountBalance', value: 'AccountBalance' },
      { text: 'Type', value: 'AccountType' },
      { text: '', value: 'ExportRecords' },
      { text: '', value: 'GetInfo' },
    ],
  }),
  methods: {
    exportRecords(item) {
      const payload = {
        searchDate: this.date,
        scheme: item.AccountType
      }
      this.$store.dispatch('DepositsHeld/exportRecords', payload) 
    },
    getData() {
      this.loading = true
      this.menu = false
      this.$store.dispatch('DepositsHeld/getAccounts', this.date).then(() => {
        this.loading = false
      })
    },
    getRecords(item) {
      const payload = {
        searchDate: this.date,
        scheme: item.AccountType
      }
      this.fetchingRecords = true
      if(!this.records) {
        this.$store.dispatch('DepositsHeld/getRecords', payload).then( () => this.fetchingRecords = false).catch( () => this.fetchingRecords = false)
      }
      this.depositsHeldRecordsDialog = true
      this.recordDialog.type =  item.AccountType
    },
    closeDepositsRecordsDialog() {
      this.depositsHeldRecordsDialog = false
      this.$store.commit('DepositsHeld/CLEAR_RECORDS')
    },
    toCurrencyFilter
  },
  computed: {
    ...mapState({
      accounts: state => state.DepositsHeld.accounts,
      records: state => state.DepositsHeld.records
    }),
    ...mapGetters({
      currencyData: "CurrencyData/currencyInfo"
    }),
    computedDateFormattedMomentjs () {
      return this.date ? moment(this.date).format('MMMM YYYY') : ''
    },
  },
  created() {
    this.loading = true
    this.$store.dispatch('DepositsHeld/getAccounts', this.date).then(() => {
      this.loading = false
    })

  }
}

</script>